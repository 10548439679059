@import '../../styles/tools.scss';

.PasswdForgot {
}

.PasswdForgot__content {
  @include container(430px);

  padding-top: 32px;
}

.PasswdForgot__title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}

.PasswdForgot__connection {
  margin-bottom: 32px;
  padding: 32px 16px;
  background: $greyLight;

  @include breakpoint(tablet) {
    padding: 32px 24px;
  }
}
.PasswdForgot__infos{
  font-size: 16px;
  margin-bottom: 18px;
}
.PasswdForgot__form {
  margin-bottom: 18px;
}
.PasswdForgot__footer{
  text-align: center;
}
.PasswdForgot__success{
  display: block;
  font-size: 14px;
  color: $green;
  margin-top: 3px;
  margin-bottom: 5px;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 12px;
    margin-left: 4px;
    border-right: solid 2px $green;
    border-bottom: solid 2px $green;
    transform: rotate(45deg);
    margin-right: 8px;
  }
}
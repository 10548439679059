@import '../../../styles/tools.scss';

.Input {
  margin-bottom: 16px;
}

.Input__label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 6px;
}
.Input__label_infos {
  font-size: 14px;
  color:#757575;
  font-weight: 400;
}


.Input__wrap {
  display: block;
  position: relative;
}

.Input__field{
  @include clear-input();

  width: 100%;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px #d6d6df;
  background-color: #ffffff;
  font-size: 16px;
  color: $black;

  .-error & {
    border: solid 1px $warning;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($blue, .3);
  }
}

.Input__togglePassword {
  @include clear-button();

  position: absolute;
  top: 50%;
  right: 16px;
  text-decoration: underline;
  font-size: 14px;
  transform: translateY(-50%);
}

.Input__errors {
  font-size: 14px;
  color: $warning;
  margin-top: 3px;
}

.Input__error{
  display: block;

  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 2px;
    background: url('../../../img/error.svg') no-repeat center;
    background-size: contain;
  }
}
@import '../../styles/tools.scss';
@import '../../styles/mixins.scss';

.SubscriptionMergeApprovement {
}
b{
  font-weight: 500;
}
.SubscriptionMergeApprovement__content {
  @include container(800px);

  padding-top: 32px;
}

.SubscriptionMergeApprovement__title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}
.SubscriptionMergeApprovement__subtitle {
  font-size: 16px;
  text-align: left;
  margin: 16px 0 25px;
  padding: 0 100px;

  @include breakpoint(mobile, tablet) {
    padding:0px 10px;
  }
}
.SubscriptionMergeApprovement__connection {
  margin-bottom: 32px;
  padding: 32px 100px;
  background: $greyLight;

  @include breakpoint(tablet) {
    padding: 32px 100px;
  }
  @include breakpoint(mobile, tablet) {
    padding: 32px 10px;
  }
}
.SubscriptionMergeApprovement__text {
  margin: 20px 0;
}
.SubscriptionMergeApprovement__help {
  text-align: center;
}

.SubscriptionMergeApprovement__imgPhoneNumber {
  width: 249px;
  max-width: 100%;
  height: auto;
}


.SubscriptionMergeApprovement__help__bold {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.SubscriptionMergeApprovement__help__text {
  font-size: 16px;
  margin-bottom: 9px;
}
.SubscriptionMergeApprovement__input__help {
  margin-top: -10px;
}
.SubscriptionMergeApprovement__submit{
  @include clear-button();

  width: 100%;
  padding: 14px;
  background: $red;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  text-align: center;

  &:hover{
    background: $redHover;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 16px;
    margin-right: 20px;
    border-right: solid 2px #FFF;
    border-bottom: solid 2px #FFF;
    transform: rotate(45deg);
  }
}
@import '../../styles/tools.scss';
@import '../../styles/mixins.scss';

.SubscriptionMerge {
}
b{
  font-weight: 500;
}
.SubscriptionMerge__content {
  @include container(800px);

  padding-top: 32px;
}

.SubscriptionMerge__title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}
.SubscriptionMerge__subtitle {
  font-size: 16px;
  text-align: left;
  margin: 16px 0 25px;
  padding: 0 100px;

  @include breakpoint(mobile, tablet) {
    padding:0px 10px;
  }
}
.SubscriptionMerge__connection {
  margin-bottom: 32px;
  padding: 32px 100px;
  background: $greyLight;

  @include breakpoint(tablet) {
    padding: 32px 100px;
  }
  @include breakpoint(mobile, tablet) {
    padding: 32px 10px;
  }
}
.SubscriptionMerge__text {
  margin: 20px 0;
}

.SubscriptionMerge__recap__container{
  border: solid 2px #ffeebb;
  background-color: #ffeebb;
}
.SubscriptionMerge__recap__text{
  text-align: center;
  padding: 20px 50px;
}
.SubscriptionMerge__table__container{
  padding: 20px;
  background-color: #FFF;
  margin: 0;
}
.SubscriptionMerge__table{
  width: 100%;

  tbody {
    display: flex;

    @include breakpoint(mobile, desktop) {
      display:block;
    }

    tr{
      @include breakpoint(desktop) {
        &:nth-child(1) {
          flex: 1;
        }
        &:nth-child(2) {
          flex: 3;
        }
      }
    }

    th, td{
      width: 100%;
      display:block;
      text-align: left;
    }
    th{
      font-weight: 500
    }

  }
}
.SubscriptionMerge__form {
  margin-bottom: 18px;
  padding: 0 50px;
  @include breakpoint(mobile, tablet) {
    padding: 0px;
  }
}
.SubscriptionMerge__cancel {
  padding: 0px 100px;
  margin-top: 20px;
  @include breakpoint(mobile, desktop) {
    padding: 0px;
  }
}
.SubscriptionMerge__help {
  text-align: center;
}

.SubscriptionMerge__imgPhoneNumber {
  width: 249px;
  max-width: 100%;
  height: auto;
}


.SubscriptionMerge__help__bold {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.SubscriptionMerge__help__text {
  font-size: 16px;
  margin-bottom: 9px;
}
.SubscriptionMerge__input__help {
  margin-top: -10px;
}
.SubscriptionMergeApprovement__submit{
  @include clear-button();

  width: 100%;
  padding: 14px;
  background: $red;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  text-align: center;

  &:hover{
    background: $redHover;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 16px;
    margin-right: 20px;
    border-right: solid 2px #FFF;
    border-bottom: solid 2px #FFF;
    transform: rotate(45deg);
  }
}
.SubscriptionMerge__success{
  display: block;
  font-size: 14px;
  color: $green;
  margin-top: 3px;
  margin-bottom: 5px;
  text-align: center;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 12px;
    margin-left: 4px;
    border-right: solid 2px $green;
    border-bottom: solid 2px $green;
    transform: rotate(45deg);
    margin-right: 8px;
  }
}
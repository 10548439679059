@import '../../styles/tools.scss';

/* menu burger */
$burger-line: 2px;
$burger-height: 20px;
$burger-width: 28px;
$burger-speed: 0.3s;
$burger-color: #FFF;

.HeaderConnected {
  position: relative;
  background: $red;
}

.HeaderConnected__container {
  @include container();
}

.HeaderConnected__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;

  @include breakpoint(desktop) {
    padding: 31px 0px;
  }
}

.HeaderConnected__home {

}

.HeaderConnected__logo {
  display: block;
  width: 108px;
  height: auto;

  @include breakpoint(desktop) {
    width: 129px;
  }
}

.HeaderConnected__button {
  @include clear-button();

  background: $redDark;
  border-radius: 4px;
  color: #FFF;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
}

.HeaderConnected__burger{
  @include clear-button();

  display: block;
  padding: 8px 8px;

  @include breakpoint(desktop) {
    display: none;
  }
}

.HeaderConnected__burger__anim {
  position: relative;
  width: $burger-width;
  height: $burger-height;
  cursor: pointer;

  &::before,
  span,
  &::after {
    position: absolute;
    left: 0;
    height: $burger-line;
    width: 100%;
    background: $burger-color;
    border-radius: $burger-line/2;
    transition: opacity 0s linear $burger-speed/2, background 0.2s linear;
  }

  &::before {
    animation: closeTop $burger-speed ease-in-out 0s 1 both;
    content: '';
    top: 0;
  }

  span {
    top: 50%;
    margin-top: -$burger-line/2;
    cursor: pointer;
  }

  &::after {
    animation: closeBot $burger-speed ease-in-out 0s 1 both;
    content: '';
    bottom: 0px;
  }

  .-openedMenu &{

    &:before {
      animation: openTop $burger-speed ease-in-out 0s 1 both;
    }

    span {
      opacity: 0;
    }

    &:after {
      animation: openBot $burger-speed ease-in-out 0s 1 both;
    }

  }

  @keyframes openTop {
    0% { transform: translateY(0) rotateZ(0deg); }
    50% { transform: translateY($burger-height / 2 - $burger-line / 2) rotateZ(0deg); }
    100% { transform: translateY($burger-height / 2 - $burger-line / 2) rotateZ(-45deg); }
  }

  @keyframes openBot {
    0% { transform: translateY(0) rotateZ(0deg); }
    50% { transform: translateY(-($burger-height / 2 - $burger-line / 2)) rotateZ(0deg); }
    100% { transform: translateY(-($burger-height / 2 - $burger-line / 2)) rotateZ(45deg); }
  }

  @keyframes closeTop {
    100% { transform: translateY(0) rotateZ(0deg); }
    50% { transform: translateY($burger-height / 2 - $burger-line / 2) rotateZ(0deg); }
    0% { transform: translateY($burger-height / 2 - $burger-line / 2) rotateZ(-45deg); }
  }

  @keyframes closeBot {
    100% { transform: translateY(0) rotateZ(0deg); }
    50% { transform: translateY(-($burger-height / 2 - $burger-line / 2)) rotateZ(0deg); }
    0% { transform: translateY(-($burger-height / 2 - $burger-line / 2)) rotateZ(45deg); }
  }

}

.HeaderConnected__banner {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background: $black url('../../img/banner.png') no-repeat center;
    background-size: cover;
    background-position: left center;
  }

  @include breakpoint(desktop) {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100vw - 30%);
    height: 100%;
  }
}

.HeaderConnected__banner__title {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
  padding: 13px;
  text-align: center;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(36, 36, 37, 0) 5%, #000 75%, #000);;
  }

  span{
    position: relative;
    display: block;
    max-width: 270px;
    margin: auto;
  }

  @include breakpoint(desktop) {
    font-size: 20px;
    margin: 0;
    text-align: left;
    line-height: 72px;

    span{
      max-width: 100%;
      margin: 0;
    }
  }
}
@import './styles/tools.scss';

html{
  height: 100%;
}
body{
  font-family: 'Karbon', 'helvetica', 'arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  min-height: 100%;
  color: $black;
  padding-bottom: 30px;

  a { color: $black; }
}

@font-face {
  font-family: 'Karbon';
  src: url('./fonts/Karbon/Karbon-Bold.woff2') format('woff2'),
      url('./fonts/Karbon/Karbon-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karbon';
  src: url('./fonts/Karbon/Karbon-Light.woff2') format('woff2'),
      url('./fonts/Karbon/Karbon-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karbon';
  src: url('./fonts/Karbon/Karbon-Regular.woff2') format('woff2'),
      url('./fonts/Karbon/Karbon-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karbon';
  src: url('./fonts/Karbon/Karbon-Medium.woff2') format('woff2'),
      url('./fonts/Karbon/Karbon-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@import '../../styles/tools.scss';

.Header {
  background: $red;
}

.Header__container {
  @include container();
}

.Header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;

  @include breakpoint(tablet) {
    padding: 31px 0px;
  }
}

.Header__home {

}

.Header__logo {
  display: block;
  width: 108px;
  height: auto;

  @include breakpoint(tablet) {
    width: 129px;
  }
}
.Header__welcome {
  font-size: 1.2em;
  font-weight: 500;
  color: #FFF;
}
.Header__button {
  @include clear-button();

  background: $redDark;
  border-radius: 4px;
  color: #FFF;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;

  &:hover{
    background: $redHover;
  }
}

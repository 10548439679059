@import '../../styles/tools.scss';

.Home {
}

.Home__container {
  @include container();

  display: flex;
}

.Home__title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.Home__text {
  font-size: 14px;
  margin-bottom: 20px;

  b {
    font-weight: 500;
  }

  a {
    color: $red;
    text-decoration: none;
  }
}

.Home__brand {
  font-size: 14px;
  margin-bottom: 30px;
}
.Home__brand__secure {
  margin-bottom: -4px;
  margin-right: 5px;
}
.Home__brand__paybox {
  margin-bottom: -5px;
  width: 64px;
  margin-left: 5px;
}


/* table  */
.Home__table {
  width: 100%;
  font-size: 16px;
}
.Home__table__head {
  @include breakpoint(mobile, tablet) {
    display: none;
  }

  border-bottom: solid 1px $greyMedium;

  th {
    text-align: left;
    padding: 10px 10px 10px 0px;
    font-weight: 500;
  }
}

.Home__table__body {
  border-bottom: solid 1px $grey;

  @include breakpoint(mobile, tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.Home__table__body__facture {
  font-size: 14px;
}

.Home__table__body__paid {
  color: $green;
  font-weight: 500;
  white-space: nowrap;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 12px;
    margin-left: 4px;
    border-right: solid 2px $green;
    border-bottom: solid 2px $green;
    transform: rotate(45deg);
    margin-right: 8px;
  }
}
.Home__table__body__noPaid {
  display: block;
  color: $red;
  font-weight: bold;
  text-decoration: none;
  border:none;
  background: none;
  cursor:pointer;
  padding: none;


  &::after {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    border-right: solid 2px $red;
    border-bottom: solid 2px $red;
    transform: rotate(-45deg);
  }
  &:hover{
    text-decoration: underline;
  }

  span {
    white-space: nowrap;
  }
}
.Home__table__body__unpaid {
  display: block;
  color: $red;
  font-weight: bold;
  text-decoration: none;
  border:none;
  background: none;
  cursor:pointer;
  padding: none;

  span {
    white-space: nowrap;
  }
}

.Home__table__download {
  @include clear-button();

  @include breakpoint(mobile, tablet) {
    display: block;
    width: 100%;
    text-align: center;
    background-color: $greyMedium;
    padding: 14px 20px;
    font-size: 18px;
    font-weight: 500;

    &::after {
      content: 'Télécharger facture'
    }
  }
}

.Home__table__download__icon {
  @include breakpoint(mobile, tablet) {
    width: 25px;
    margin-top: -2px;
    margin-bottom: -6px;
    margin-right: 8px;
  }
}

.Home__table__head__mobile {
  font-weight: 500;

  @include breakpoint(tablet) {
    display: none;
  }
}

.Td {
  text-align: left;
  padding: 10px 20px 10px 0px;

  @include breakpoint(mobile, tablet) {
    width: 100% ;
    padding: 10px 0px 10px 0px;
  }
}

.Td__mobile {
  text-align: left;
  padding: 10px 20px 10px 0px;

  @include breakpoint(mobile, tablet) {
    display: block;
    &.flex { width: 100% }
    padding: 10px 0px 10px 0px;
  }
}

.Td__content {
  display: flex;

  .flex & {display: flex;}
  .block & {display: block;}
}

.Td__head {
  flex: 0 0 40%;
  width: 40%;
  font-weight: 500;

  @include breakpoint(tablet) {
    display: none;
  }
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  font-size: 14px;

  b {
    font-weight: 500;
  }

  a {
    color: $red;
    text-decoration: none;
  }
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal input{
    @include clear-button();

    width: 50%;
    padding: 14px;
    background: $red;
    font-size: 18px;
    font-weight: 500;
    color: #FFF;
    text-align: center;

    &:hover{
      background: $redHover;
    }

}
@import '../../styles/tools.scss';

.Footer {
  border-top: solid 1px $greyLight;
  padding-bottom:20px;
}
.Footer__content {
  text-align: center;
  padding: 30px 0;
}

.Footer__wrap {
  @include breakpoint(mobile, tablet) {
    display: block;
    margin-bottom: 10px;
  }
}

.Footer__link {
  color: $red;
  text-decoration: none;
}

.Footer__link2 {
  color: $red;
  text-decoration: none;
  font-weight: 500;
  margin-right: 10px;
}

.Footer__logo {
  display: inline-block;
  margin: -20px 0;
}
@import '../../styles/tools.scss';
@import '../../styles/mixins.scss';

.PaymentRefused {
}
.PaymentRefused__text{
  display: block;
  font-size: 14px;
  color: $green;
  margin-top: 3px;
  margin-bottom: 5px;
  text-align:center;
  margin-top:50px;

  &::before {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    border-right: solid 2px $red;
    border-bottom: solid 2px $red;
    transform: rotate(-45deg);
    margin-right: 15px;
  }
}
@import '../../styles/tools.scss';
@import '../../styles/mixins.scss';

.UpdateEmail {
}

.UpdateEmail__container {
  @include container();
  width: 100%;
  display: flex;
}

.UpdateEmail__title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.UpdateEmail__text {
  font-size: 14px;
  margin-bottom: 20px;
  min-width: 100%;

  b {
    font-weight: 500;
  }

  a {
    color: $red;
    text-decoration: none;
  }
}

.UpdateEmail__form {
  width: 40%;
  margin: 0 auto;

  @include breakpoint(tablet, desktop) {
    width: 60%;
  }
  @include breakpoint(mobile, tablet) {
    width: 100%;
  }
}
.UpdateEmail__input {

  @include clear-input();

  width: 100%;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px #d6d6df;
  background-color: #ffffff;
  font-size: 16px;
  color: $black;

  .-error & {
    border: solid 1px $warning;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($blue, .3);
  }
}
.UpdateEmail__buttonsContainer{
  display: flex;
  width: 80%;
  margin: 0 auto 100px;

  > * {
    flex: 1;
    margin: 5px;
  }
}

.UpdateEmail__error{
  display: block;
  font-size: 14px;
  color: $warning;
  margin-top: 3px;
  margin-bottom: 5px;

  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 2px;
    background: url('../../img/error.svg') no-repeat center;
    background-size: contain;
  }
}
.UpdateEmail__success{
  display: block;
  font-size: 14px;
  color: $green;
  margin-top: 3px;
  margin-bottom: 5px;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 12px;
    margin-left: 4px;
    border-right: solid 2px $green;
    border-bottom: solid 2px $green;
    transform: rotate(45deg);
    margin-right: 8px;
  }
}
.UpdateEmail__homeBtn{
  text-decoration: none;
  margin-top:-10px;
}
@import '../../styles/tools.scss';
@import '../../styles/mixins.scss';

.ValidatePersonalInfos {
}

.ValidatePersonalInfos__content {
  @include container(800px);

  padding-top: 32px;
}

.ValidatePersonalInfos__title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}
.ValidatePersonalInfos__subtitle {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin: 40px 0 16px;
}

.ValidatePersonalInfos__connection {
  margin-bottom: 32px;
  padding: 32px 200px;
  background: $greyLight;

  @include breakpoint(tablet) {
    padding: 32px 200px;
  }
  @include breakpoint(mobile, tablet) {
    padding: 32px 10px;
  }
}

.ValidatePersonalInfos__form {
  margin-bottom: 18px;
}

.ValidatePersonalInfos__help {
  text-align: center;
}

.ValidatePersonalInfos__imgPhoneNumber {
  width: 249px;
  max-width: 100%;
  height: auto;
}


.ValidatePersonalInfos__help__bold {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.ValidatePersonalInfos__help__text {
  font-size: 16px;
  margin-bottom: 9px;
}
.ValidatePersonalInfos__input__help {
  margin-top: -10px;
  padding-bottom: 20px;
}
.ValidatePersonalInfos__input {

  @include clear-input();

  width: 100%;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px #d6d6df;
  background-color: #ffffff;
  font-size: 16px;
  color: $black;

  .-error & {
    border: solid 1px $warning;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($blue, .3);
  }
}
.ValidatePersonalInfos__input__small {

  @include clear-input();

  width: 50%;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px #d6d6df;
  background-color: #ffffff;
  font-size: 16px;
  color: $black;

  @include breakpoint(mobile, tablet) {
    width: 100%;
  }

  .-error & {
    border: solid 1px $warning;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($blue, .3);
  }
}

.ValidatePersonalInfos__error{
  display: block;
  font-size: 14px;
  color: $warning;
  margin-top: 3px;
  margin-bottom: 5px;

  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 2px;
    background: url('../../img/error.svg') no-repeat center;
    background-size: contain;
  }
}

.ValidatePersonalInfos__radios{
  height:40px;
  display: flex;
  > div {
    flex: 1;
    > label{
      margin-left: 30px;
      line-height: 25px;
    }
    > input{
      position: absolute;
      height:15px;
      margin-top: 5px;
    }
  }
}
@import '../../styles/tools.scss';

.Login {
}

.Login__warning {
 background: $warningBack;
 padding: 16px 0;
 text-align: center;
}

.Login__warning__bold {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 6px;
}

.Login__warning__text {
  font-size: 16px;
}

.Login__content {
  @include container(389px);

  padding-top: 32px;
}

.Login__title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}

.Login__connection {
  margin-bottom: 32px;
  padding: 32px 16px;
  background: $greyLight;

  @include breakpoint(tablet) {
    padding: 32px 24px;
  }
}

.Login__form {
  margin-bottom: 18px;
}

.Login__forgot {
  display: block;
  color: $red;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.Login__help {
  text-align: center;
}

.Login__imgPhoneNumber {
  width: 249px;
  max-width: 100%;
  height: auto;
}


.Login__help__bold {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

.Login__help__text {
  font-size: 16px;
  margin-bottom: 9px;
}

.Login__error{
  display: block;
  font-size: 14px;
  color: $warning;
  margin-top: 3px;
  margin-bottom: 5px;

  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 2px;
    background: url('../../img/error.svg') no-repeat center;
    background-size: contain;
  }
}
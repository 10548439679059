@import '../../../styles/tools.scss';

.Checkbox {
  margin-bottom: 16px;
}

.Checkbox__group {
  display: flex;
}

.Checkbox__field {
  position: absolute;
  left: -100vw;
}

.Checkbox__status {
  position: relative;
  flex: 1 0 14px;
  display: block;
  margin-right: 8px;
  margin-top: 2px;
  width: 14px;
  height: 14px;
  border-radius: 1px;
  border: solid 1px $greyMedium;
  background-color: #ffffff;

  .Checkbox__field:focus ~ &{
    box-shadow: 0 0 0 2px rgba($blue, .3);
  }

  .Checkbox__field:checked ~ &::after{
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    background-color: $blue;
  }

  .-error & {
    border: solid 1px $warning;
  }

}


.Checkbox__label {
  font-size: 16px;
}

.Checkbox__errors {
  font-size: 14px;
  color: $warning;
  margin-top: 3px;
}

.Checkbox__error{
  display: block;

  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 2px;
    background: url('../../../img/error.svg') no-repeat center;
    background-size: contain;
  }
}
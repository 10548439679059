@import '../../styles/tools.scss';

.LayoutSubscription {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  min-height: 100vh;
}
.LayoutSubscription__header {
  background: $red;
}
.LayoutSubscription__content {
  flex: 1 0 100%;
  height: 100%;
}

.LayoutSubscription__Header__container {
  @include container();
}

.LayoutSubscription__Header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;

  @include breakpoint(tablet) {
    padding: 31px 0px;
  }
}

.LayoutSubscription__Header__logo {
  display: block;
  width: 108px;
  height: auto;

  @include breakpoint(tablet) {
    width: 129px;
  }
}

.LayoutSubscription__Header__button {
  @include clear-button();

  background: $redDark;
  border-radius: 4px;
  color: #FFF;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
}
@import '../../../styles/tools.scss';

.Select {
  margin-bottom: 16px;
  max-width: 288px;
}

.Select__label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 6px;
}


.Select__wrap {
  display: block;
  position: relative;

  &::after {
    content:'';
    position: absolute;
    top: 50%;
    right: 5px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    background: url('../../../img/ar-down.svg') no-repeat center;
    pointer-events: none;
  }
}

.Select__field{
  @include clear-input();

  width: 100%;
  padding: 16px;
  border-radius: 3px;
  border: solid 1px #d6d6df;
  background-color: #ffffff;
  font-size: 16px;
  color: $black;

  .-error & {
    border: solid 1px $warning;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($blue, .3);
  }
}

.Select__error{
  display: block;

  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 2px;
    background: url('../../../img/error.svg') no-repeat center;
    background-size: contain;
  }
}
@import './responsive.scss';

$red: #eb0000;
$redHover: #EB3939;
$redDark: #bf0303;
$blue: #2e58ff;
$green: #4f833f;
$warningBack: #ffeebb;
$black: #111010;
$greyLight: #f2f2f2;
$greyMedium: #d0d0d6;
$grey: #e3e3e5;
$warning: #eb0000;

@mixin container($maxWidth: 1200px) {
  // position: relative;
  max-width: $maxWidth;
  width: calc(#{$maxWidth} + 16px * 2);
  margin: auto;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;

  @include breakpoint(mobile, desktop) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }

}

@mixin objectFit($type: cover){
  @supports ( object-fit : $type ) {
    object-fit: $type;
    @content;
  }
}
@mixin ie11() {
  ::ng-deep _:-ms-lang(x), & {
    @content;
  }
}

/* button reset */
@mixin clear-button ($outline: false) {
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @if $outline { outline: none; }
}

/* input reset */
@mixin clear-input {
  border: none;
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* button */

$buttonType: (
  green: (border: none, color: #FFF, background: #FFF, backHover: lighten($black, 5%)),
);
@mixin button($type: green){
  @include clear-button();

  display: inline-block;
  width: auto;
  min-width: 120px;
  margin: auto;
  padding: 18px 28px 21px 28px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  border-radius: 100px;
  transition: all 300ms ease;
  
  color: map-get(map-get($buttonType, $type), color);
  border: map-get(map-get($buttonType, $type), border);
  background: map-get(map-get($buttonType, $type), background);

  &:not([disabled]):hover{
    background: map-get(map-get($buttonType, $type), backHover);
  }

  &[disabled]{
    opacity: .5;
    cursor: default;
  }
}

/* wysiwyg */
@mixin wysiwyg($color: $black, $size:false, $weight:false){
  counter-reset: section;
  font-size: 16px;

  h2, h3, h4, h5, h6 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 900;
    color: $color;
  }
  p{
    color: $color;
    font-size: 16px;
    line-height: 20px;
    
    &:empty{
      margin-bottom: 20px;
    }
  }
  a{
    color: $color;
  }
  ol{
    padding-left: 20px;
    margin-bottom: 20px;
    
    li{
      color: $color;

      &::before{
        counter-increment: section;
        content: counter(section);
        display: inline-block;
        margin-right: 20px;
        font-size: 10px;
      }
    }
  }
  ul{
    padding-left: 35px;
    margin: 20px 0;
    
    li{
      position: relative;
      color: $color;

      &::before{
        content: '•';
        position: absolute;
        left: -15px;
        top: 3px;
        font-size: 12px;
      }
    }
  }
 
  img{
    display: block;
    max-width: 100%;
    height: auto;
    margin: 10px auto;
  }

  @content;
}


@import '../../styles/tools.scss';

.UpdatePasswd {
}

.UpdatePasswd__content {
  @include container(430px);

  padding-top: 32px;
}

.UpdatePasswd__title {
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
}

.UpdatePasswd__connection {
  margin-bottom: 32px;
  padding: 32px 16px;
  background: $greyLight;

  @include breakpoint(tablet) {
    padding: 32px 24px;
  }
}
.UpdatePasswd__infos{
  font-size: 16px;
  margin-bottom: 18px;
}
.UpdatePasswd__form {
  margin-bottom: 18px;
}
.UpdatePasswd__footer{
  text-align: center;
}
.UpdatePasswd__success{
  display: block;
  font-size: 14px;
  color: $green;
  margin-top: 3px;
  margin-bottom: 5px;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 12px;
    margin-left: 4px;
    border-right: solid 2px $green;
    border-bottom: solid 2px $green;
    transform: rotate(45deg);
    margin-right: 8px;
  }
}
.UpdatePasswd__error{
  display: block;
  font-size: 14px;
  color: $warning;
  margin-top: 3px;
  margin-bottom: 5px;

  &::before {
    content: '';
    display: inline-block;
    width: 19px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 2px;
    background: url('../../img/error.svg') no-repeat center;
    background-size: contain;
  }
}
@import '../../styles/tools.scss';

.Menu {
  @include breakpoint(mobile, desktop) {
    @include container();
  }
}

.Menu__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px $greyLight;
}

.Menu__section {
  margin-bottom: 15px;
}

.Menu__user__icon {
  margin-bottom: -3px;
  margin-right: 2px;
}

.Menu__user {
  color: $red;
  font-weight: 500;
}

.Menu__title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.Menu__text {
  font-size: 16px;
  margin-bottom: 3px;
}

.Menu__link {
  color: $red;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}
@import '../../styles/tools.scss';

.LayoutConnected {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  min-height: 100vh;
}
.LayoutConnected__header {
}
.LayoutConnected__content {
  flex: 1 0 100%;
  height: 100%;
}

.LayoutConnected__content {
  @include container();

  display: flex;
  padding-top: 30px;
}

.LayoutConnected__content__sidebar {

  @include breakpoint(mobile, tablet) {
    z-index: 99;
    position: fixed; 
    top: 61px;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: #FFF;
    padding-top: 20px;

    transition: opacity 300ms ease, visibility 300ms ease;
    opacity: 0;
    visibility: hidden;

    &.-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  @include breakpoint(tablet, desktop) {
    z-index: 99;
    position: absolute;
    top: 61px;
    right: 0;
    width: 100vw;
    max-width: 425px;
    height: 100vh;
    background: #FFF;
    box-shadow: 0 0 5px 0 rgba(24, 22, 22, 0.16);
    padding-top: 20px;

    transition: opacity 300ms ease, visibility 300ms ease;
    opacity: 0;
    visibility: hidden;

    &.-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  @include breakpoint(desktop) {
    width: 25%;
    padding-right: 20px;
  }
}

.LayoutConnected__content__children {
  width: 100%;

  @include breakpoint(desktop) {
    width: 75%;
    padding-left: 20px;
  }
}
@import '../../../styles/tools.scss';

.Cancel {
  @include clear-button();

  &:hover{
    background: #ECE9E9;
  }
  width: 100%;
  padding: 14px;
  background: #dbdbdb;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

@import '../../styles/tools.scss';
@import '../../styles/mixins.scss';

.EmailUpdated {
}
.EmailUpdated__text{
  display: block;
  font-size: 14px;
  color: $green;
  margin-top: 30px;
  margin-bottom: 5px;
  text-align:center;

  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 12px;
    margin-left: 4px;
    border-right: solid 2px $green;
    border-bottom: solid 2px $green;
    transform: rotate(45deg);
    margin-right: 8px;
  }
}
@import '../../styles/tools.scss';

.Layout {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  min-height: 100vh;
}
.Layout__header {
}
.Layout__content {
  flex: 1 0 100%;
  height: 100%;
}

@import '../../../styles/tools.scss';

.Submit {
  @include clear-button();

  width: 100%;
  padding: 14px;
  background: $red;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  text-align: center;

  &:hover{
    background: $redHover;
  }
}
